import { useContext, useEffect } from 'react'
import Header from './components/Header'
import Dashboard from './components/Dashboard'
import Subscriptions from './components/landing/Subscription'
import Footer from './components/Footer'
import Landing from './components/landing/Landing'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ProtectedRoute } from './components/PrivateRoute'
import SubscriptionWrapper from './containers/SubscriptionWrapper'
import Newsletter from './components/landing/Newsletter'
import { AuthContext } from './containers/AuthWrapper'
import { useAccount } from 'wagmi'

function App(): React.ReactElement {
  const { address: account } = useAccount()
  const { openProviderMenu, closeProviderMenu, isOpenProviderMenu, isAuth } = useContext(
    AuthContext
  )
  const history = useHistory()

  useEffect(() => {
    if (!isAuth) {
      closeProviderMenu()
      history.push('/')
    }
  }, [isAuth])

  return (
    <SubscriptionWrapper>
      <div className="App">
        <div className="page">
          <div className="page__header">
            <Header openProviderMenu={openProviderMenu} isLoggedIn={isAuth} />
          </div>
          <div className="page__body">
            <Switch>
              <Route exact path="/">
                <Landing
                  openDashboard={(): void => {
                    !isOpenProviderMenu && openProviderMenu()
                  }}
                />
              </Route>
              <Route path="/pricing">
                <ProtectedRoute isAuth={isAuth}>
                  <Subscriptions />
                </ProtectedRoute>
              </Route>
              <Route exact path="/projects">
                <ProtectedRoute isAuth={isAuth}>
                  <Dashboard account={account} />
                  <Newsletter />
                </ProtectedRoute>
              </Route>
            </Switch>
          </div>
          <div className="page__footer">
            <Footer />
          </div>
        </div>
      </div>
    </SubscriptionWrapper>
  )
}

export default App
