import { Line } from 'react-chartjs-2'
import { Chart } from 'chart.js'
import uniqolor from 'uniqolor'
import { DexGuruProject, DexGuruProjectData, DexGuruRange } from '../model'
import * as chartjs from 'chart.js'

Chart.pluginService.register({
  afterDraw: (chart: any) => {
    const activePoints = chart.tooltip._active
    const groupStroke = chart.options.tooltips.groupStroke

    if (activePoints && activePoints.length && groupStroke) {
      const activePoint = activePoints[0],
        ctx = chart.ctx,
        x = activePoint.tooltipPosition().x,
        bottomY = chart.chartArea.bottom

      let topY = chart.chartArea.top

      const points: any = []
      chart.tooltip._active.forEach((tooltip: any) => {
        if (tooltip._model.backgroundColor !== 'transparent') points.push(tooltip._model.y)
      })
      const max = Math.max.apply(null, points)
      const min = Math.min.apply(null, points)

      if (groupStroke.end === 'min') {
        topY = max
      }
      if (groupStroke.end === 'max') {
        topY = min
      }

      // draw line
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = chart.options.tooltips.groupStroke.width
      ctx.strokeStyle = chart.options.tooltips.groupStroke.style
      ctx.stroke()
      ctx.restore()
    }
  },
})

interface StatChartProps {
  projectsList: DexGuruProject[]
  projectsData: DexGuruProjectData[]
  viewProject: number
  yAxisMax?: number
  rangeOptions: DexGuruRange[]
  statRange: number
}

export const StatChart = (props: StatChartProps): React.ReactElement => {
  const data: { labels: string[]; datasets: any[] } = {
    labels: [],
    datasets: [],
  }

  const projectsList = props.projectsList || []
  const projectsData = props.projectsData || [{ requestsByRange: [] }]

  if (!projectsList.length) {
    return <></>
  }

  projectsData[projectsData.length - 1]?.requestsByRange.forEach((item: any) => {
    const time = new Date()
    time.setMilliseconds(item.timestamp || 0)
    data.labels.push(
      time.toLocaleString(
        'en-US',
        props.rangeOptions[props.statRange].options || { month: 'short', day: 'numeric' }
      )
    )
  })

  projectsList.forEach((prj, index) => {
    if (!(props.viewProject < 0 || props.viewProject === prj.index)) {
      return
    }

    const requests: any = []

    projectsData[prj.id]?.requestsByRange?.forEach((item) => {
      requests.push(item)
    })

    data.datasets.push({
      label: prj.title || 'No title',
      data: requests,
      fill: false,
      borderColor: uniqolor(`${index}_${prj.title}`).color,
    })
  })

  const options: chartjs.ChartOptions & {
    datasetStrokeWidth?: number
    pointDotStrokeWidth?: number
    tooltips: { groupStroke?: any }
  } = {
    responsive: true,
    datasetStrokeWidth: 3,
    tooltips: {
      mode: 'index',
      intersect: false,
      enabled: false,
      borderColor: 'rgba(0, 0, 0, 1)',
      borderWidth: 1,
      groupStroke: {
        style: 'rgba(255,255,255,.2)',
        width: 1,
        end: 'max',
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontFamily: 'Sora, sans-serif',
            fontSize: 12,
            fontColor: '#545C66',

            max: props.yAxisMax,
            maxTicksLimit: 4,
          },
          gridLines: {
            drawBorder: false,
            drawOnChartArea: true,
            color: '#29313A',
            drawTicks: false,
            tickMarkLength: 10,
            zeroLineColor: '#29313A',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontFamily: 'Sora, sans-serif',
            fontSize: 12,
            fontColor: '#545C66',
            padding: 4,
            maxRotation: 0,
            minRotation: 0,
            autoSkip: true,
          },
          gridLines: {
            drawOnChartArea: false,
            drawTicks: true,
            tickMarkLength: 6,
            color: '#29313A',
          },
        },
      ],
    },
    pointDotStrokeWidth: 0,
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  }

  return (
    <>
      <div className="chart">
        <Line type={'interactedLine' as chartjs.ChartType} data={data} options={options} />
      </div>
    </>
  )
}

export default StatChart
