import React, { useCallback, useContext } from 'react'
import { dexGuruLoginApiV2Url } from '../settings'
import { apiFetch } from '@telekomconsalting/dex-guru-fetch'
import { AuthContext } from './AuthWrapper'

export const SubscriptionContext = React.createContext<Subscription>({} as Subscription)

export interface Subscription {
  customer: string
  id: number
  product: string
  product_name: string
  product_url: string
  requests_used: number
  requests_limit: number
  limit_expire_at: number
}

const SubscriptionWrapper = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[]
}): React.ReactElement | null => {
  const [subscriptions, setSubscriptions] = React.useState({} as Subscription)
  const { isAuth } = useContext(AuthContext)
  const getSubscriptions = useCallback(async (): Promise<void> => {
    apiFetch(dexGuruLoginApiV2Url, '/auth/keys/limit').then(setSubscriptions).catch(console.error)
  }, [])

  React.useEffect(() => {
    if (isAuth) {
      getSubscriptions()
    }
  }, [isAuth, getSubscriptions])

  return (
    <SubscriptionContext.Provider value={subscriptions}>{children}</SubscriptionContext.Provider>
  )
}

export default SubscriptionWrapper
