import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './scss/index.scss'
import App from './App'
import NetworkConfigWrapper from './containers/NetworkConfigWrapper'
import loadable from '@loadable/component'
import Web3ReactWrapper from './containers/Web3ReactWrapper'

const SafeProvider = loadable(() => import('@gnosis.pm/safe-apps-react-sdk'), { fallback: <div /> })

ReactDOM.render(
  <React.StrictMode>
    <SafeProvider>
      <Router>
        <NetworkConfigWrapper>
          <Web3ReactWrapper>
            <App />
          </Web3ReactWrapper>
        </NetworkConfigWrapper>
      </Router>
    </SafeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
