import React from 'react'
import { Subscription } from '../containers/SubscriptionWrapper'
import dayjs from 'dayjs'

interface PlanInfoProps {
  subscription: Subscription
}

const PlanInfo = ({ subscription }: PlanInfoProps): React.ReactElement => {
  const { product_name, product_url, requests_limit, limit_expire_at } = subscription

  if (!subscription.requests_limit) {
    return <></>
  }

  return (
    <div className="projects-panel__plan projects-panel__info">
      <div className="row">
        <span className="title">You current plan: </span>
        <span className="value">{product_name || 'Free tier'}</span>
      </div>
      <div className="projects-panel__property">
        <span className="title">API URL: </span>
        <a className="url" href={product_url}>
          {product_url?.replace('https://', '')}
        </a>
      </div>
      <div className="row">
        <span className="title">Expires: </span>
        <span className="value">
          {limit_expire_at ? dayjs.unix(limit_expire_at).format('DD-MMM-YYYY HH:mm') : 'N/A'}
        </span>
      </div>
      <div className="projects-panel__property">
        <span className="title">Total requests: </span>
        <span className="value">{requests_limit}</span>
      </div>
    </div>
  )
}

export default PlanInfo
