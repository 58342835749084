import classNames from 'classnames'
import { useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../containers/AuthWrapper'

interface IntroProps {
  openDashboard: () => void
}

const Intro = (props: IntroProps): React.ReactElement => {
  const introRef = useRef<any>()
  const history = useHistory()
  const { isAuth } = useContext(AuthContext)

  useEffect((): any => {
    const onScroll = (): void => {
      if (introRef.current) {
        const scrollTop = window.pageYOffset
        const introTop = introRef.current.offsetTop
        const diff = scrollTop - introTop
        introRef.current.style = `--scroll-delta: ${diff + 'px'}`
      }
    }
    window.addEventListener('scroll', onScroll)

    return (): void => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <div
      className={classNames('landing-intro', { 'landing-intro--no-auth': !isAuth })}
      ref={introRef}>
      <div className="landing-intro__wrapper">
        <div className="landing-intro__illustration">
          <img className="img img--01" src="/assets/img/landing/intro/cartridge--01.png" alt="" />
          <img className="img img--02" src="/assets/img/landing/intro/cartridge--02.png" alt="" />
          <img className="img img--03" src="/assets/img/landing/intro/cartridge--03.png" alt="" />
          <img className="img img--04" src="/assets/img/landing/intro/cartridge--04.png" alt="" />
          <img className="img img--05" src="/assets/img/landing/intro/cartridge--05.png" alt="" />
          <img className="img img--06" src="/assets/img/landing/intro/cartridge--06.png" alt="" />
          <img className="img img--07" src="/assets/img/landing/intro/cartridge--07.png" alt="" />
          <img className="img img--08" src="/assets/img/landing/intro/cartridge--08.png" alt="" />
        </div>
        <div className="landing-intro__header">
          <h1 className="landing-intro__title">
            <span className="caption">
              Dex.Guru <span className="blue">instant on-chain</span> market{' '}
              <span className="green">data API</span>
            </span>
          </h1>
          <span className="landing-intro__subtitle">
            With a Free Tier plan, you can use up to 200,000 requests monthly. Data is delayed for
            300sec. There is a Premium API plan available at $199/mo. If you are looking for
            Enterprise plan please book a demo below.
          </span>
        </div>
        <div className="landing-intro__body">
          <a
            href="/projects"
            className="action action--main action--alt"
            onClick={(e): void => {
              e.preventDefault()
              if (isAuth) {
                history.push('/projects')
              } else {
                props.openDashboard()
              }
            }}>
            <span className="preload-image" />
            <span className="caption">Create a&nbsp;project</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Intro
