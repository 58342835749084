const Footer = (): React.ReactElement => {
  return (
    <footer className="footer">
      <div className="footer__header">
        <div className="footer-meta">
          <span className="footer__copyright">© {new Date().getFullYear()} dex.guru</span>
          <span className="footer__version">Version Alpha 0.1</span>
        </div>
      </div>
      <div className="footer__links footer-links">
        <ul className="footer-links__list">
          <li className="footer-links__item">
            <a href="https://dex.guru" target="_blank" rel="noopener noreferrer">
              Dex.Guru
            </a>
          </li>
          <li className="footer-links__item">
            <a href="https://blog.dex.guru" target="_blank" rel="noopener noreferrer">
              Blog
            </a>
          </li>
          <li className="footer-links__item">
            <a href="https://api.dev.dex.guru/docs" target="_blank" rel="noopener noreferrer">
              Docs
            </a>
          </li>
          <li className="footer-links__item">
            <a href="https://twitter.com/dexguru" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          </li>
          <li className="footer-links__item">
            <a href="https://discord.gg/dPW8fzwzz9" target="_blank" rel="noopener noreferrer">
              Discord
            </a>
          </li>
          <li className="footer-links__item">
            <a
              href="https://docs.dex.guru/legal/terms-of-service"
              target="_blank"
              rel="noopener noreferrer">
              Terms of Service
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
