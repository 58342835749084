import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

export type ProtectedRouteProps = { isAuth: boolean } & RouteProps

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuth,
  children,
  ...routeProps
}) => {
  const isStoredAuth = localStorage.getItem('accessToken')
  if (isAuth || isStoredAuth) {
    return <Route {...routeProps}>{children}</Route>
  } else {
    return <Redirect to={'/'} />
  }
}
