import { useContext } from 'react'
import cn from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../images/logo.svg'
import { SubscriptionContext } from '../containers/SubscriptionWrapper'
import React from 'react'
import WalletButton from './WalletButton'

interface HeaderProps {
  isLoggedIn: boolean
  openProviderMenu: () => void
}

const Header = (props: HeaderProps): React.ReactElement => {
  const toggleMenu = (): void => {
    // not good idea for react
    document.querySelector('body')?.classList.toggle('is--switched-menu')
  }

  const subscriptionContext = useContext(SubscriptionContext)

  const location = useLocation()

  if (props.isLoggedIn && Object.keys(subscriptionContext).length === 0) {
    return <></>
  }

  return (
    <header className="header">
      <Link className="header__logo" to="/">
        <Logo className="logo" />
      </Link>
      <button className="header__toggle" onClick={toggleMenu}>
        <span className="caption">Menu</span>
      </button>
      <div className="header__body">
        <nav className="header__menu header__menu--main">
          <ul className="header-links">
            <li className="header-links__item">
              <a
                href="https://blog.dex.guru/"
                target="_blank"
                rel="noopener noreferrer"
                className="header-links__link">
                <span className="caption">Blog</span>
              </a>
            </li>
            <li className="header-links__item">
              <a
                href="https://api.dev.dex.guru/docs"
                target="_blank"
                rel="noopener noreferrer"
                className="header-links__link">
                <span className="caption">Docs</span>
              </a>
            </li>
            <li className="header-links__item">
              <a
                href="https://discord.com/invite/dPW8fzwzz9"
                target="_blank"
                rel="noopener noreferrer"
                className="header-links__link">
                <span className="caption">Community</span>
              </a>
            </li>
          </ul>
        </nav>
        {props.isLoggedIn && (
          <nav className="header__menu header__menu--dashboard">
            <ul className="header-links">
              <li className="header-links__item">
                <Link
                  className={cn('header-links__link', {
                    active: props.isLoggedIn && location.pathname === '/projects',
                  })}
                  to="/projects">
                  <span className="caption">My Projects</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        {props.isLoggedIn && (
          <nav className="header__menu header__menu--dashboard">
            <ul className="header-links">
              <li className="header-links__item">
                {!subscriptionContext.product ? (
                  <Link
                    className={cn('header-links__link', {
                      active: props.isLoggedIn && location.pathname === '/pricing',
                    })}
                    to="/pricing">
                    <span className="caption">Pricing</span>
                  </Link>
                ) : (
                  <a
                    className={cn('header-links__link')}
                    href={process.env.REACT_APP_MY_ACCOUNT_URL || ''}
                    target="_blank"
                    rel="noreferrer">
                    <span className="caption">My Account</span>
                  </a>
                )}
              </li>
            </ul>
          </nav>
        )}

        <div className="header__wallet">
          <WalletButton />
        </div>
      </div>
    </header>
  )
}

export default React.memo(Header, (prevProps, props) => props.isLoggedIn === prevProps.isLoggedIn)
