import jazzicon from '@metamask/jazzicon'
import React, { useEffect, useRef } from 'react'

interface IWalletAvatar {
  account?: string
  size?: 'xxs' | 'xs' | 'sm' | 'md'
  className?: string
  classNamePrefix: string
  onClick?: () => void
}

const ICON_SIZE = 32

const WalletAvatar: React.FC<IWalletAvatar> = ({ account, size = 'sm', classNamePrefix }) => {
  let styleIcon
  const avatarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = avatarRef.current

    if (!element) {
      return
    }

    if (element.firstChild) {
      element.removeChild(element.firstChild)
    }

    if (account) {
      const addr = account.slice(2, 10)
      const seed = parseInt(addr, 16)
      const icon = jazzicon(ICON_SIZE, seed)
      const background = icon.style.getPropertyValue('background')
      icon.removeAttribute('style')
      icon.setAttribute('style', `background:${background}`)
      icon.className = 'wallet-avatar__avimage'
      element.appendChild(icon)
    }
  }, [account, avatarRef, classNamePrefix])

  return (
    <div className={`wallet-avatar wallet-avatar--${size}`} style={styleIcon}>
      <div className="wallet-avatar__image" ref={avatarRef} />
    </div>
  )
}

export default WalletAvatar
