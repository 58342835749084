import React from 'react'
import { dexGuruAPIV2Url } from '../settings'
import { NetworkConfig } from '../model'
import { apiFetch } from '@telekomconsalting/dex-guru-fetch'

export const NetworksContext = React.createContext<NetworkConfig[]>([])

const NetworkConfigWrapper = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[]
}): React.ReactElement | null => {
  const [networksConfig, setNetworkConfig] = React.useState<NetworkConfig[]>([])

  React.useEffect(() => {
    getNetworksConfig()
  }, [])

  const getNetworksConfig = async (): Promise<void> => {
    apiFetch(dexGuruAPIV2Url, '/chain/').then((res) => {
      const networks: NetworkConfig[] = res?.data
      setNetworkConfig(networks)
    })
  }

  if (!networksConfig.length) {
    // TODO loader?
    return null
  }

  return <NetworksContext.Provider value={networksConfig}>{children}</NetworksContext.Provider>
}

export default NetworkConfigWrapper
