import { Chain } from 'wagmi'
import * as viemChains from 'viem/chains'
import { NetworkConfig } from './model'
import get from 'lodash.get'

export const noop = (): void => {
  return
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const mapToWagmiChainsModel = (networksConfig: NetworkConfig[]): Chain[] => {
  return networksConfig
    .filter((x) => x.rpc_url)
    .map((guruChain) => {
      const viemChainName = Object.keys(viemChains).find(
        (chainName) => get(viemChains, chainName).id === guruChain.id
      )
      const viemChain = viemChainName ? get(viemChains, viemChainName) : undefined

      const result = {
        ...viemChain,
        id: guruChain.id,
        name: guruChain.description,
        network: guruChain.name,
        iconUrl: guruChain.logo_uri,
        nativeCurrency: {
          name: guruChain.native_token.name,
          symbol: guruChain.native_token.symbol,
          decimals: guruChain.native_token.decimals,
        },

        rpcUrls: {
          default: {
            http: [guruChain.rpc_url as string],
          },
          public: {
            http: [guruChain.rpc_url as string],
          },
        },
        blockExplorers: {
          default: {
            name: guruChain.block_explorer_display_name,
            url: guruChain.block_explorer_url,
          },
        },
        testnet: false,
      }
      return result
    })
}
