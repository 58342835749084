import { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import { ReactComponent as Copy } from '../images/icons/buttons/copy.svg'
import { ReactComponent as Menu } from '../images/icons/buttons/more.svg'
import { ReactComponent as Close } from '../images/icons/buttons/close.svg'
import { apiFetch } from '@telekomconsalting/dex-guru-fetch'
import { DexGuruProject } from '../model'
import { dexGuruProjectsAPIUrl } from '../settings'

Modal.setAppElement('#root')

interface ProjectProps {
  updateProjects?: () => void
  create?: boolean
  stopCreate?: () => void
  project?: DexGuruProject
  onSelect?: (value: number) => void
  color?: string
  title?: string
  account: any
}

const Project = (props: ProjectProps): React.ReactElement => {
  const projectRef = useRef<any>()
  const [isEditable, setEditable] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isConfirmOpen, setConfirmOpen] = useState(false)

  function useOnClickOutside(ref: any, handler: (event: any) => void): void {
    useEffect(() => {
      const listener = (event: any): void => {
        if (!ref?.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return (): void => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  useOnClickOutside(projectRef, () => setMenuOpen(false))

  const [isFocused, setFocused] = useState(false)

  const deleteProject = async (id?: number): Promise<void> => {
    setConfirmOpen(false)

    if (!id) {
      return
    }

    const response = await apiFetch(dexGuruProjectsAPIUrl, `/projects/${id}?account_id=${props.account}`, {
      init: {
        method: 'DELETE',
      },
    })

    if (!response) {
      console.log('Error while submitting new project')
    } else {
      props?.updateProjects && props.updateProjects()
      setFocused(false)
      setEditable(false)
      setMenuOpen(false)
    }
  }

  const submitProject = async (data: any, method?: any, id?: number): Promise<void> => {
    const response = await apiFetch(
      dexGuruProjectsAPIUrl,
      `/projects/${id ? id : ''}?account_id=${props.account}`,
      {
        init: {
          method: method || 'POST',
          body: JSON.stringify(data),
        },
      }
    )

    if (!response) {
      console.log('Error while submitting new project')
    } else {
      props?.updateProjects && props.updateProjects()
      setFocused(false)
      setEditable(false)
      setMenuOpen(false)
    }
  }

  const updateKey = async (id?: number, currentKey?: string): Promise<void> => {
    if (!id || !currentKey) {
      return
    }

    const response = await apiFetch(dexGuruProjectsAPIUrl, `/projects/${id ? id : ''}/regenerate_api_key`, {
      init: {
        method: 'PATCH',
        body: JSON.stringify({ current_api_key: currentKey || '' }),
      },
    })

    if (!response) {
      console.log('Error while updating project key')
    } else {
      props?.updateProjects && props.updateProjects()
      setMenuOpen(false)
    }
  }

  const createProject = (e: any): void => {
    e.preventDefault()
    const title = e.currentTarget?.elements['project-title']?.value || false
    if (!title) {
      console.log('No title set')
      return
    }
    submitProject({ title: title })
  }

  const updateProject = (e: any): void => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form) {
      return
    }
    const id = form.elements['project_id']?.value || false
    if (!id) {
      console.log('No project id set')
      return
    }
    const title = form.elements['project-title']?.value || false
    if (!title) {
      console.log('No project title set')
      return
    }
    submitProject({ title: title }, 'PATCH', id)
  }

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text).then(() => console.log(`Key copied: ${text}`))
  }

  const onClickLink = (): void => {
    setEditable(true)
    setMenuOpen(false)
  }

  const onTitleClick = (): void => {
    if (props.onSelect) {
      const index = props.project?.index || 0
      props.onSelect(index > 0 ? index : 0)
    }
  }

  return (
    <div className={classNames('project', { 'project--new': props.create })}>
      <div className="project__header">
        {props.create ? (
          <form className="project-control" onSubmit={createProject}>
            <div
              className={classNames('project-control__name', {
                'project-control__name--focus': isFocused,
              })}
            >
              <input
                autoFocus
                type="text"
                name="project-title"
                onFocus={(): void => {
                  setFocused(true)
                }}
                onBlur={(): void => {
                  setFocused(false)
                }}
                className="project-control__input"
                placeholder="Project name"
              />
            </div>
            <div className="project-control__action">
              <button
                type="submit"
                className="button button--xl button--accept projects-control__create"
              >
                Create
              </button>
            </div>
            <div className="project-control__action">
              <button
                type="reset"
                className="button button--xl button--decline projects-control__cancel"
                onClick={props.stopCreate}
              >
                Cancel
              </button>
            </div>
          </form>
        ) : !isEditable ? (
          <>
            <h3 className="project__title" role="button" onClick={onTitleClick}>
              <span className="caption">{props.project?.title || '<Untitled>'}</span>
            </h3>
            <div className="project__menu">
              <div
                className={classNames('project-menu', {
                  'project-menu--open': isMenuOpen,
                })}
                ref={projectRef}
              >
                <button
                  onClick={(): void => setMenuOpen(!isMenuOpen)}
                  className="project-menu__toggle"
                >
                  {isMenuOpen ? <Close className="icon" /> : <Menu className="icon" />}
                  <span className="caption">Project actions</span>
                </button>
                <div className="project-menu__dropdown">
                  <span className="project-menu__link" role="button" onClick={onClickLink}>
                    Edit name
                  </span>
                  <span
                    className="project-menu__link"
                    role="button"
                    onClick={(): void => {
                      updateKey(props.project?.id, props.project?.api_key)
                    }}
                  >
                    Generate a new key
                  </span>
                  <hr />
                  <span
                    className="project-menu__link project-menu__link--danger"
                    role="button"
                    onClick={(): void => {
                      setConfirmOpen(true)
                    }}
                  >
                    Delete project
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <form className="project-control" onSubmit={updateProject}>
            <input type="hidden" name="project_id" value={props.project?.id || ''} />
            <div
              className={classNames('project-control__name', {
                'project-control__name--focus': isFocused,
              })}
            >
              <input
                autoFocus
                type="text"
                name="project-title"
                defaultValue={props.project?.title || ''}
                onFocus={(): void => {
                  setFocused(true)
                }}
                onBlur={(): void => {
                  setFocused(false)
                }}
                className="project-control__input"
                placeholder="Project name"
              />
            </div>
            <div className="project-control__action">
              <button
                type="submit"
                className="button button--lg button--accept projects-control__update"
              >
                Save
              </button>
            </div>
            <div className="project-control__action">
              <button
                type="reset"
                onClick={(): void => setEditable(false)}
                className="project-control__close"
              >
                <Close className="icon" />
                <span className="caption">Close</span>
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="project__body">
        <div className="project__requests">
          <strong className="project__label">Requests</strong>{' '}
          <span className="project__value" style={props.create ? {} : { color: props.color }}>
            {(props.project?.total_requests || 0).toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,')}
          </span>
        </div>
        <div className="project__key">
          <strong className="project__label">API Key</strong>{' '}
          <div className="project-apikey">
            <input
              className="project-apikey__input"
              type="text"
              value={props.project?.api_key || ''}
              placeholder={'• '.repeat(16)}
              disabled
            />
            {!props.create ? (
              <button
                className="project-apikey__copy"
                onClick={(): void => {
                  copyToClipboard(props.project?.api_key || 'No API key copied!')
                }}
              >
                <Copy className="icon" />
                <span className="caption">Copy API key</span>
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isConfirmOpen}
        onRequestClose={(): void => {
          setConfirmOpen(false)
        }}
        overlayClassName="modal-overlay"
        className="modal-box"
      >
        <div className="modal-box__header">
          <h4 className="modal-box__title">
            <div className="caption">Are you sure you want to delete this project?</div>
          </h4>
          <div className="modal-box__action">
            <button
              type="reset"
              onClick={(): void => {
                setConfirmOpen(false)
              }}
              className="modal-box__close"
            >
              <Close className="icon" />
              <span className="caption">Close</span>
            </button>
          </div>
        </div>
        <div className="modal-box__body">
          <div className="text">
            This project will be removed from your profile and you will no longer have access to it.
          </div>
        </div>
        <div className="modal-box__footer">
          <div className="row row--justify-center">
            <div className="cell cell--auto">
              <button
                className="button button--lg button--decline"
                onClick={(): void => {
                  deleteProject(props.project?.id)
                }}
              >
                <span className="caption">Delete</span>
              </button>
            </div>
            <div className="cell cell--auto">
              <button
                className="button button--lg button--accept"
                onClick={(): void => {
                  setConfirmOpen(false)
                }}
              >
                <span className="caption">Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default Project
