const Newsletter = (): React.ReactElement => {
  return (
    <div className="newsletter">
      <iframe
        src="https://dexguru.substack.com/embed"
        width="480"
        height="320"
        scrolling="no"
        className="newsletter__content"
        title="Substack Subscription"></iframe>
    </div>
  )
}

export default Newsletter
