import { useState } from 'react'

import StatChart from './StatsChart'
import { DexGuruProject, DexGuruProjectData, DexGuruRange } from '../model'
import classNames from 'classnames'

import { ReactComponent as Back } from '../images/icons/buttons/back.svg'
import { ReactComponent as Close } from '../images/icons/buttons/close.svg'
import { ReactComponent as Expand } from '../images/icons/buttons/expand.svg'

interface ProjectStatsProps {
  projectsList: DexGuruProject[]
  projectsData: DexGuruProjectData[]
  viewProject: number
  exitView: () => void
  rangeOptions: DexGuruRange[]
  statRange: number
  setStatRange: (idx: number) => void
}

const ProjectStats = (props: ProjectStatsProps): React.ReactElement => {
  const [statsExpanded, setStatsExpanded] = useState(false)

  return (
    <div className="dashboard-content__stats">
      {props.projectsList.length ? (
        <div
          className={classNames('projects-stats', {
            'projects-stats--view': props.viewProject >= 0,
          })}>
          {props.viewProject >= 0 ? (
            <div className="projects-stats-project__header">
              <button
                onClick={(): void => {
                  props.exitView()
                }}
                className="projects-stats-project__back">
                <Back className="icon" />

                <span className="caption">Back</span>
              </button>
              <h2 className="projects-stats-project__title">
                <span className="caption">{props.projectsList[props.viewProject]?.title}</span>
              </h2>
            </div>
          ) : (
            ''
          )}
          <div className="projects-stats__header">
            <h3 className="projects-stats__title">
              <span className="caption">
                {props.viewProject >= 0 ? 'Requests ' : 'Total requests '}over the last
              </span>
            </h3>
            <div className="projects-stats__action">
              <button
                className="projects-stats__toggle"
                onClick={(): void => {
                  setStatsExpanded(!statsExpanded)
                }}>
                {statsExpanded ? <Close className="icon" /> : <Expand className="icon" />}
                <span className="caption">Toggle view</span>
              </button>
            </div>
          </div>
          <div
            className={classNames('projects-stats__body', {
              'projects-stats__body--expanded': statsExpanded,
            })}>
            <div className="projects-stats__period">
              <div className="row row--no-gap">
                {props.rangeOptions.map((range, idx) => (
                  <div className="cell cell--auto" key={idx}>
                    <button
                      className={`button button--switch button--xs projects-stats__switch ${
                        props.statRange === idx ? 'active' : ''
                      }`}
                      data-range={idx}
                      onClick={(): void => {
                        props.setStatRange(idx)
                      }}>
                      <span className="caption">{range.title}</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <strong className="projects-stats__value">
              {props.projectsList
                .reduce(
                  (sum, current) =>
                    sum + (props.projectsData[current.id]?.requestsByRangeTotal || 0),
                  0
                )
                .toString()
                .replace(/(\d)(?=(\d{3})+$)/g, '$1,')}
            </strong>
            <div className="projects-stats__chart">
              <StatChart
                projectsList={props.projectsList}
                projectsData={props.projectsData}
                viewProject={props.viewProject}
                rangeOptions={props.rangeOptions}
                statRange={props.statRange}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ProjectStats
