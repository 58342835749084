import { useContext, useEffect, useState } from 'react'

import uniqolor from 'uniqolor'
import Project from './Project'

import { apiFetch } from '@telekomconsalting/dex-guru-fetch'
import { DexGuruProject, DexGuruProjectData } from '../model'
import { dexGuruProjectsAPIUrl, dexGuruProxyApi } from '../settings'
import ProjectStats from './ProjectStats'
import { useJwtToken } from '../hooks/useJwtToken'
import { SubscriptionContext } from '../containers/SubscriptionWrapper'
import PlanInfo from './PlanInfo'

interface DashboardProps {
  account: any
}

const Dashboard = ({ account }: DashboardProps): React.ReactElement => {
  const { account_id } = useJwtToken()

  const subscriptionContext = useContext(SubscriptionContext)

  const IS_FREE_PLAN =
    !subscriptionContext.product_name ||
    subscriptionContext.product_name?.toLowerCase().includes('free')

  const rangeOptions = [
    {
      title: '24 hours',
      range: 24 * 60 * 60,
      interval: 1 * 60 * 60,
      options: { hour: '2-digit', minute: '2-digit' },
    },
    {
      title: '1 hour',
      range: 1 * 60 * 60,
      interval: 10 * 60,
      options: { hour: '2-digit', minute: '2-digit' },
    },
    {
      title: '7 days',
      range: 7 * 24 * 60 * 60,
      interval: 12 * 60 * 60,
      options: { month: 'short', day: 'numeric' },
    },
    {
      title: '30 days',
      range: 30 * 24 * 60 * 60,
      interval: 24 * 60 * 60,
      options: { month: 'short', day: 'numeric' },
    },
  ]

  const [statRange, setStatRange] = useState(0)
  const [statProject, setStatProject] = useState(-1)
  const [isNewProject, setNewProject] = useState(false)
  const [projectsList, setProjectsList] = useState<DexGuruProject[]>([])
  const [projectsData, setProjectsData] = useState<DexGuruProjectData[]>([])

  const loadData = async (): Promise<void> => {
    setNewProject(false)
    const response = await apiFetch(dexGuruProjectsAPIUrl, `/projects/`)

    if (!response) {
      return
    }

    const projects: DexGuruProject[] = []

    response?.data?.forEach((project: DexGuruProject, index: number) => {
      const prj = project
      prj.index = index
      projects.push(prj)
    })

    setProjectsList(projects)
  }

  // TODO: abstract from component
  const getProjectsRange = async (startDate: number, interval: number): Promise<void> => {
    const response = await apiFetch(
      dexGuruProxyApi,
      `/statistics?start_date=${startDate}&interval=${interval}&account_id=${account_id}`
    )

    const data: any[] = []

    response?.forEach((element: any) => {
      const timestamps = Object.keys(element)

      for (let i = 0; i < timestamps.length; i++) {
        element[timestamps[i]].forEach((entry: any) => {
          if (!data[entry.project_id]) {
            data[entry.project_id] = { requestsByRange: [], requestsByRangeTotal: 0 }
          }
          data[entry.project_id].requestsByRange.push(entry.requests_count)
          data[entry.project_id].requestsByRangeTotal += entry.requests_count
        })
      }
    })

    setProjectsData(data)
  }

  const statRangeChange = async (index: number): Promise<void> => {
    if (!rangeOptions[index]) {
      return
    }

    const range = rangeOptions[index].range
    const interval = rangeOptions[index].interval

    setStatRange(index)

    if (!range) {
      return
    }

    const startDate = Math.round((Date.now() - range * 1000) / 1000)

    getProjectsRange(startDate, interval)
  }

  const selectActiveProject = (index: number): void => {
    setStatProject(!projectsList[index] ? -1 : index)
  }

  useEffect((): void => {
    loadData()
  }, [])

  useEffect((): void => {
    statRangeChange(statRange)
  }, [statRange])

  useEffect((): void => {
    statRangeChange(statRange)
  }, [projectsList])

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h1 className="dashboard__title">
          <span className="caption">My Projects</span>
        </h1>
      </div>
      <div className="dashboard__body">
        {IS_FREE_PLAN && (
          <>
            <p className="dashboard__text">
              With a Free Tier plan, you can use up to 200,000 requests monthly. Data is delayed for
              300sec. There is a <a href="https://developers.dex.guru/pricing">Premium API</a> plan
              available at $199/mo. If you are looking for Enterprise plan please book a demo below.
            </p>
            <a
              className="projects-demo button button--md button--tretiary projects-panel__create"
              href="https://webforms.pipedrive.com/f/6xOZrWov4Ip6VHoQ6tcPL7M0uYkONa3OVkzbyldt2D8LDdAJA6CQThKur3NAjEfU1J"
              target="_blank"
              rel="noreferrer">
              Book a demo
            </a>
          </>
        )}
        <div className="dashboard-content">
          <div className="dashboard-content__toolbox">
            <span className="preload-image" />
            {!isNewProject ? (
              <div className="projects-panel">
                <div className="projects-panel__action">
                  <button
                    className="button button--lg button--primary projects-panel__create"
                    onClick={(): void => {
                      setNewProject(true)
                    }}>
                    Create a project
                  </button>
                </div>
              </div>
            ) : (
              <Project
                title=""
                create={true}
                stopCreate={(): void => {
                  setNewProject(false)
                }}
                updateProjects={loadData}
                account={account}
              />
            )}
          </div>
          <PlanInfo subscription={subscriptionContext} />

          <ProjectStats
            projectsList={projectsList}
            projectsData={projectsData}
            viewProject={statProject}
            exitView={(): void => {
              setStatProject(-1)
            }}
            rangeOptions={rangeOptions}
            statRange={statRange}
            setStatRange={setStatRange}
          />
          <div className="dashboard-content__projects">
            <div className="projects">
              {projectsList && projectsList.length
                ? projectsList.map((prj, idx) => (
                    <Project
                      key={idx}
                      onSelect={selectActiveProject}
                      project={prj}
                      color={uniqolor(`${idx}_${prj.title}`).color}
                      updateProjects={loadData}
                      account={account}
                    />
                  ))
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
