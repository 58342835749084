import * as React from 'react'
import { useJwtToken } from '../../hooks/useJwtToken'

const Subscriptions = (): React.ReactElement => {
  const { account_id } = useJwtToken()
  return (
    <div className="subscriptions">
      <div className="subscriptions__header">
        <h1 className="subscriptions__title">
          <span className="caption">Pricing plans</span>
        </h1>
      </div>
      <div className="subscriptions__plans">
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_PUBLISHABLE_KEY}
          client-reference-id={account_id}></stripe-pricing-table>
      </div>
    </div>
  )
}
export default Subscriptions
