import '@rainbow-me/rainbowkit/styles.css'

import {
  AuthenticationStatus,
  ConnectButton,
  darkTheme,
  DisclaimerComponent,
  getDefaultWallets,
  RainbowKitProvider,
  Theme,
} from '@rainbow-me/rainbowkit'
import React, {
  FC,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Chain, configureChains, createConfig, WagmiConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'

import { NetworksContext } from './NetworkConfigWrapper'
import { WALLET_CONNECT_PROJECT_ID } from '../settings'
import { mapToWagmiChainsModel } from '../utils'
import AuthWrapper from './AuthWrapper'
import RainbowKitAuthWrapper from './RainbowKitAuthWrapper'
import merge from 'lodash.merge'

export type RainbowKitState = {
  authenticationStatus: AuthenticationStatus
  connectModalOpen: boolean
}

export const RainbowKitContext = React.createContext<RainbowKitState>({
  authenticationStatus: 'authenticated',
  connectModalOpen: false,
})

const customTheme = merge(darkTheme({ borderRadius: 'medium' }), {
  colors: {
    modalBackground: '#1e242c',
    accentColor: '#9488f0',
    connectButtonBackground: '#1e242c',
  },
  fonts: {
    body: 'Sora, sans-serif',
  },
} as Theme)

const Dummy: FC<{
  connectModalOpen: boolean
  authenticationStatus?: AuthenticationStatus
  onConnectModalOpenChange: (connectModalOpen: boolean) => void
  onAuthenticationStatusChange: (authenticationStatus: AuthenticationStatus) => void
}> = ({
  connectModalOpen,
  authenticationStatus,
  onConnectModalOpenChange,
  onAuthenticationStatusChange,
}) => {
  useEffect(() => {
    authenticationStatus && onAuthenticationStatusChange(authenticationStatus)
  }, [authenticationStatus])
  useEffect(() => {
    connectModalOpen && onConnectModalOpenChange(connectModalOpen)
  }, [connectModalOpen])
  return null
}

const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
  <Text>
    By connecting wallet you agree to the{' '}
    <Link href="https://docs.dex.guru/legal/terms-of-service">Terms of Service</Link> and{' '}
    <Link href="https://docs.dex.guru/legal/privacy-policy">Privacy Policy</Link>
  </Text>
)

const Web3ReactWrapper: FC = ({ children }) => {
  const networksConfig = useContext(NetworksContext)
  const chains = useRef<Chain[]>()

  const [authenticationStatusState, setAuthenticationStatusState] = useState<AuthenticationStatus>(
    'unauthenticated'
  )
  const [connectModalOpenState, setConnectModalOpenState] = useState<boolean>(false)

  const onConnectModalOpenChange = useCallback((connectModalOpen) => {
    connectModalOpenState !== connectModalOpen && setConnectModalOpenState(connectModalOpen)
  }, [])

  const onAuthenticationStatusChange = useCallback((authenticationStatus) => {
    authenticationStatus &&
      authenticationStatusState !== authenticationStatus &&
      setAuthenticationStatusState(authenticationStatus)
  }, [])

  const wagmiConfig = useMemo(() => {
    const { chains: configuredChains, publicClient } = configureChains(
      mapToWagmiChainsModel(networksConfig),
      [publicProvider()]
    )
    chains.current = configuredChains
    const { connectors } = getDefaultWallets({
      appName: 'Developers DexGuru App',
      chains: configuredChains,
      projectId: WALLET_CONNECT_PROJECT_ID,
    })

    const config = createConfig({
      autoConnect: true,
      connectors,
      publicClient,
    })
    return config
  }, [networksConfig])

  if (!chains.current || !wagmiConfig) {
    return null
  }

  return (
    <Suspense fallback={<></>}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitAuthWrapper>
          <RainbowKitProvider
            theme={customTheme}
            chains={chains.current}
            appInfo={{
              appName: 'Developers Dex.Guru',
              disclaimer: Disclaimer,
            }}>
            <ConnectButton.Custom>
              {({ connectModalOpen, authenticationStatus }): ReactNode => {
                return (
                  <Dummy
                    connectModalOpen={connectModalOpen}
                    onConnectModalOpenChange={onConnectModalOpenChange}
                    authenticationStatus={authenticationStatus}
                    onAuthenticationStatusChange={onAuthenticationStatusChange}
                  />
                )
              }}
            </ConnectButton.Custom>
            <RainbowKitContext.Provider
              value={{
                authenticationStatus: authenticationStatusState,
                connectModalOpen: connectModalOpenState,
              }}>
              <AuthWrapper>{children}</AuthWrapper>
            </RainbowKitContext.Provider>
          </RainbowKitProvider>
        </RainbowKitAuthWrapper>
      </WagmiConfig>
    </Suspense>
  )
}

export default Web3ReactWrapper
