import Intro from './Intro'
import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../containers/AuthWrapper'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useHistory } from 'react-router-dom'

interface LandingProps {
  openDashboard: () => void
}

const Landing = (props: LandingProps): React.ReactElement => {
  const { isAuth } = useContext(AuthContext)
  const history = useHistory()
  const { openConnectModal, connectModalOpen } = useConnectModal()
  useEffect(() => {
    if (!isAuth && !connectModalOpen && openConnectModal) {
      openConnectModal()
    }
  }, [connectModalOpen])

  useEffect(() => {
    if (isAuth) {
      history.push('/projects')
    }
  }, [isAuth])

  return (
    <div className="landing">
      <Intro openDashboard={props.openDashboard} />
    </div>
  )
}

export default Landing
