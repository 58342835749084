import { FC, ReactElement, useContext } from 'react'
import { ReactComponent as WalletIcon } from '../images/icons/wallet.svg'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import WalletAvatar from './WalletAvatar'
import { AuthContext } from '../containers/AuthWrapper'

const WalletButton: FC = () => {
  const { isAuth } = useContext(AuthContext)

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openConnectModal,
        openAccountModal,
        authenticationStatus,
        mounted,
      }): ReactElement => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated')

        return ((): ReactElement => {
          return (
            <button
              className="wallet-account wallet-account--connected"
              onClick={isAuth ? openAccountModal : openConnectModal}>
              <div className="wallet-account__icon">
                <WalletIcon />
              </div>
              {!!connected && (
                <>
                  <div className="wallet-account__content">
                    <div className="wallet-account__name"> {account.displayName}</div>
                  </div>
                  <WalletAvatar classNamePrefix="wallet-avatar" account={account.address} />
                </>
              )}
            </button>
          )
        })()
      }}
    </ConnectButton.Custom>
  )
}

export default WalletButton
