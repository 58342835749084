import jwt_decode from 'jwt-decode'

interface UseJwtTokenResult {
  account_id: number
  limit: number
}

export function useJwtToken(): UseJwtTokenResult {
  const token = window.localStorage.getItem('accessToken') || ''
  const { account_id, limit } = jwt_decode<{ account_id: number; limit: number }>(token)

  return {
    account_id,
    limit,
  }
}
