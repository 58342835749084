import { ReactComponent as MetamaskLogo } from './images/icons/provider/metamask.svg'
import { ReactComponent as TrustWalletLogo } from './images/icons/provider/trustwallet.svg'
import { ReactComponent as WalletConnectLogo } from './images/icons/provider/walletconnect.svg'

export const environment = process.env.REACT_APP_ENVIRONMENT

const authApiHost =
  environment?.toLocaleLowerCase() !== 'production'
    ? 'https://auth-api-stage.dex.guru'
    : 'https://auth-developers.dex.guru'

export const dexGuruLoginApiV3Url = `${authApiHost}/v3`
export const dexGuruLoginApiV2Url = `${authApiHost}/v2`

export const dexGuruProjectsAPIUrl = `${authApiHost}/v2`

export const dexGuruAPIV2Url =
  environment?.toLocaleLowerCase() !== 'production'
    ? 'https://api-stage.dex.guru/v2'
    : 'https://api.dex.guru/v2'

export const dexGuruProxyApi =
  environment?.toLocaleLowerCase() !== 'production'
    ? 'https://public-stage-lax.dexguru.biz'
    : 'https://api.dev.dex.guru'

export const walletUrl = 'https://app.zerion.io/'

export const providers = {
  MetaMask: {
    name: 'MetaMask',
    reactLogo: MetamaskLogo,
  },
  TrustWallet: {
    name: 'TrustWallet',
    reactLogo: TrustWalletLogo,
  },
  WalletConnect: {
    name: 'WalletConnect',
    reactLogo: WalletConnectLogo,
  },
}

export const WALLET_CONNECT_PROJECT_ID = '1e58a7f9d4c5cbcb303af41525c9cc14'
export const JWT_TOKEN_REFRESH_INTERVAL = 60 * 60 * 1000 // 1 hour
