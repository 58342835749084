import { useConnectModal } from '@rainbow-me/rainbowkit'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useAccount } from 'wagmi'
import { noop } from '../utils'
import { RainbowKitContext } from './Web3ReactWrapper'

export type AuthContextState = {
  openProviderMenu: (callback?: () => Promise<void>) => void
  closeProviderMenu: () => void
  isOpenProviderMenu: boolean
  isAuth: boolean
  accountId?: string
}

export const AuthContext = createContext<AuthContextState>({
  isOpenProviderMenu: false,
  isAuth: false,
  accountId: undefined,
  openProviderMenu: noop,
  closeProviderMenu: noop,
})

const AuthWrapper = ({
  children,
}: {
  children: ReactNode | React.ReactElement | React.ReactElement[]
}): React.ReactElement | null => {
  const { authenticationStatus, connectModalOpen } = useContext(RainbowKitContext)
  const accessToken = window.localStorage.getItem('accessToken')
  const accountId = window.localStorage.getItem('accountId') || undefined
  const { openConnectModal } = useConnectModal()
  const { address: account } = useAccount()
  const openProviderMenu = useCallback(
    (callback?: () => Promise<void>): void => {
      openConnectModal && openConnectModal()
      callback && callback()
    },
    [openConnectModal]
  )
  useEffect(() => {
    if (connectModalOpen) {
      openProviderMenu()
    }
  }, [connectModalOpen, openProviderMenu])

  const closeProviderMenu = openConnectModal || noop
  const isAuth = useMemo(
    () => Boolean(!!accessToken && authenticationStatus === 'authenticated' && account),
    [authenticationStatus, accessToken, account]
  )

  return (
    <AuthContext.Provider
      value={{
        isOpenProviderMenu: connectModalOpen,
        isAuth,
        openProviderMenu,
        closeProviderMenu,
        accountId,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthWrapper
